import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPAutoComplete, LazyPCalendar, LazyPCascadeSelect, LazyPCheckbox, LazyPChips, LazyPColorPicker, LazyPDropdown, LazyPFloatLabel, LazyPIconField, LazyPInputGroup, LazyPInputGroupAddon, LazyPInputIcon, LazyPInputMask, LazyPInputNumber, LazyPInputOtp, LazyPInputSwitch, LazyPInputText, LazyPKnob, LazyPListbox, LazyPMultiSelect, LazyPPassword, LazyPRadioButton, LazyPRating, LazyPSelectButton, LazyPSlider, LazyPTextarea, LazyPToggleButton, LazyPTreeSelect, LazyPTriStateCheckbox, LazyPButton, LazyPButtonGroup, LazyPSpeedDial, LazyPSplitButton, LazyPColumn, LazyPRow, LazyPColumnGroup, LazyPDataTable, LazyPDataView, LazyPDataViewLayoutOptions, LazyPOrderList, LazyPOrganizationChart, LazyPPaginator, LazyPPickList, LazyPTree, LazyPTreeTable, LazyPTimeline, LazyPVirtualScroller, LazyPAccordion, LazyPAccordionTab, LazyPCard, LazyPDeferredContent, LazyPDivider, LazyPFieldset, LazyPPanel, LazyPScrollPanel, LazyPSplitter, LazyPSplitterPanel, LazyPStepper, LazyPStepperPanel, LazyPTabView, LazyPTabPanel, LazyPToolbar, LazyPConfirmDialog, LazyPConfirmPopup, LazyPDialog, LazyPDynamicDialog, LazyPOverlayPanel, LazyPSidebar, LazyPFileUpload, LazyPBreadcrumb, LazyPContextMenu, LazyPDock, LazyPMenu, LazyPMenubar, LazyPMegaMenu, LazyPPanelMenu, LazyPSteps, LazyPTabMenu, LazyPTieredMenu, LazyPMessage, LazyPInlineMessage, LazyPToast, LazyPCarousel, LazyPGalleria, LazyPImage, LazyPAvatar, LazyPAvatarGroup, LazyPBadge, LazyPBlockUI, LazyPChip, LazyPInplace, LazyPMeterGroup, LazyPScrollTop, LazyPSkeleton, LazyPProgressBar, LazyPProgressSpinner, LazyPTag, LazyPTerminal } from '#components'
const lazyGlobalComponents = [
  ["PAutoComplete", LazyPAutoComplete],
["PCalendar", LazyPCalendar],
["PCascadeSelect", LazyPCascadeSelect],
["PCheckbox", LazyPCheckbox],
["PChips", LazyPChips],
["PColorPicker", LazyPColorPicker],
["PDropdown", LazyPDropdown],
["PFloatLabel", LazyPFloatLabel],
["PIconField", LazyPIconField],
["PInputGroup", LazyPInputGroup],
["PInputGroupAddon", LazyPInputGroupAddon],
["PInputIcon", LazyPInputIcon],
["PInputMask", LazyPInputMask],
["PInputNumber", LazyPInputNumber],
["PInputOtp", LazyPInputOtp],
["PInputSwitch", LazyPInputSwitch],
["PInputText", LazyPInputText],
["PKnob", LazyPKnob],
["PListbox", LazyPListbox],
["PMultiSelect", LazyPMultiSelect],
["PPassword", LazyPPassword],
["PRadioButton", LazyPRadioButton],
["PRating", LazyPRating],
["PSelectButton", LazyPSelectButton],
["PSlider", LazyPSlider],
["PTextarea", LazyPTextarea],
["PToggleButton", LazyPToggleButton],
["PTreeSelect", LazyPTreeSelect],
["PTriStateCheckbox", LazyPTriStateCheckbox],
["PButton", LazyPButton],
["PButtonGroup", LazyPButtonGroup],
["PSpeedDial", LazyPSpeedDial],
["PSplitButton", LazyPSplitButton],
["PColumn", LazyPColumn],
["PRow", LazyPRow],
["PColumnGroup", LazyPColumnGroup],
["PDataTable", LazyPDataTable],
["PDataView", LazyPDataView],
["PDataViewLayoutOptions", LazyPDataViewLayoutOptions],
["POrderList", LazyPOrderList],
["POrganizationChart", LazyPOrganizationChart],
["PPaginator", LazyPPaginator],
["PPickList", LazyPPickList],
["PTree", LazyPTree],
["PTreeTable", LazyPTreeTable],
["PTimeline", LazyPTimeline],
["PVirtualScroller", LazyPVirtualScroller],
["PAccordion", LazyPAccordion],
["PAccordionTab", LazyPAccordionTab],
["PCard", LazyPCard],
["PDeferredContent", LazyPDeferredContent],
["PDivider", LazyPDivider],
["PFieldset", LazyPFieldset],
["PPanel", LazyPPanel],
["PScrollPanel", LazyPScrollPanel],
["PSplitter", LazyPSplitter],
["PSplitterPanel", LazyPSplitterPanel],
["PStepper", LazyPStepper],
["PStepperPanel", LazyPStepperPanel],
["PTabView", LazyPTabView],
["PTabPanel", LazyPTabPanel],
["PToolbar", LazyPToolbar],
["PConfirmDialog", LazyPConfirmDialog],
["PConfirmPopup", LazyPConfirmPopup],
["PDialog", LazyPDialog],
["PDynamicDialog", LazyPDynamicDialog],
["POverlayPanel", LazyPOverlayPanel],
["PSidebar", LazyPSidebar],
["PFileUpload", LazyPFileUpload],
["PBreadcrumb", LazyPBreadcrumb],
["PContextMenu", LazyPContextMenu],
["PDock", LazyPDock],
["PMenu", LazyPMenu],
["PMenubar", LazyPMenubar],
["PMegaMenu", LazyPMegaMenu],
["PPanelMenu", LazyPPanelMenu],
["PSteps", LazyPSteps],
["PTabMenu", LazyPTabMenu],
["PTieredMenu", LazyPTieredMenu],
["PMessage", LazyPMessage],
["PInlineMessage", LazyPInlineMessage],
["PToast", LazyPToast],
["PCarousel", LazyPCarousel],
["PGalleria", LazyPGalleria],
["PImage", LazyPImage],
["PAvatar", LazyPAvatar],
["PAvatarGroup", LazyPAvatarGroup],
["PBadge", LazyPBadge],
["PBlockUI", LazyPBlockUI],
["PChip", LazyPChip],
["PInplace", LazyPInplace],
["PMeterGroup", LazyPMeterGroup],
["PScrollTop", LazyPScrollTop],
["PSkeleton", LazyPSkeleton],
["PProgressBar", LazyPProgressBar],
["PProgressSpinner", LazyPProgressSpinner],
["PTag", LazyPTag],
["PTerminal", LazyPTerminal],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
