<script setup lang="ts">
import type { NuxtError } from '#app';

defineProps<{
  error: NuxtError;
}>();

function goBackHome() {
  clearError();
  navigateTo('/', { external: true });
}
</script>

<template>
  <NuxtLayout name="default">
    <div class="text-center py-20">
      <p class="text-base font-semibold text-indigo-600">
        {{ error.statusCode }}
      </p>
      <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
        {{ error.statusCode === 404 ? 'Page not found' : 'An error occurred' }}
      </h1>
      <p v-if="error.statusCode === 404" class="mt-6 text-base leading-7 text-gray-600">
        Sorry, we couldn't find the page you're looking for.
      </p>
      <div class="mt-10 flex items-center justify-center gap-x-6">
        <PButton label="Go back home" size="large" @click="goBackHome" />
        <a href="#" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>
      </div>
    </div>
  </NuxtLayout>
</template>
