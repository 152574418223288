import wcmatch from 'wildcard-match';

const protectedRoutes = [
  '/app/**',
  '/admin/**',
];
const isProtected = wcmatch(protectedRoutes);

export default defineNuxtRouteMiddleware((to) => {
  if (isProtected(to.path)) {
    const user = useLogtoUser();

    if (!user) {
      return navigateTo('/sign-in');
    }
  }
});
