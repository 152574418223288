import { default as accept_45invitationjOssVTmHYdMeta } from "/vercel/path0/src/pages/accept-invitation.vue?macro=true";
import { default as index6NX8DPEKEuMeta } from "/vercel/path0/src/pages/admin/areas/[area_id]/index.vue?macro=true";
import { default as _91booking_id_936XH1mT6qiQMeta } from "/vercel/path0/src/pages/admin/bookings/[booking_id].vue?macro=true";
import { default as indexIl5xrqBJZZMeta } from "/vercel/path0/src/pages/admin/bookings/index.vue?macro=true";
import { default as new85FWbRSxjVMeta } from "/vercel/path0/src/pages/admin/bookings/new.vue?macro=true";
import { default as indexwecfAS3OVsMeta } from "/vercel/path0/src/pages/admin/floors/[floor_id]/index.vue?macro=true";
import { default as _91group_id_9324vh3UBTxrMeta } from "/vercel/path0/src/pages/admin/groups/[group_id].vue?macro=true";
import { default as indexzdn9NmOJxAMeta } from "/vercel/path0/src/pages/admin/groups/index.vue?macro=true";
import { default as index1kcBTKlDMxMeta } from "/vercel/path0/src/pages/admin/index.vue?macro=true";
import { default as indexcjxv4AIu5HMeta } from "/vercel/path0/src/pages/admin/invitations/index.vue?macro=true";
import { default as indexMeh049Sek3Meta } from "/vercel/path0/src/pages/admin/members/index.vue?macro=true";
import { default as indexxxlLLSJ2eoMeta } from "/vercel/path0/src/pages/admin/offerings/[offering_id]/index.vue?macro=true";
import { default as indexP0rhIpArTTMeta } from "/vercel/path0/src/pages/admin/offerings/index.vue?macro=true";
import { default as new6xtljL2VGhMeta } from "/vercel/path0/src/pages/admin/offerings/new.vue?macro=true";
import { default as index5O8Z0x2y4ZMeta } from "/vercel/path0/src/pages/admin/resources/[resource_id]/index.vue?macro=true";
import { default as index1ne6oOrwzYMeta } from "/vercel/path0/src/pages/admin/resources/index.vue?macro=true";
import { default as generalUqlG69xhGiMeta } from "/vercel/path0/src/pages/admin/settings/general.vue?macro=true";
import { default as editTRg6EBOIxgMeta } from "/vercel/path0/src/pages/admin/site/edit.vue?macro=true";
import { default as indexqe6iXssShFMeta } from "/vercel/path0/src/pages/admin/site/index.vue?macro=true";
import { default as indextdUnxETdU8Meta } from "/vercel/path0/src/pages/admin/spaces/[space_id]/index.vue?macro=true";
import { default as _91resource_id_93PDJ9VG6jOxMeta } from "/vercel/path0/src/pages/app/[resource_id].vue?macro=true";
import { default as bookVTiuc2kNqqMeta } from "/vercel/path0/src/pages/app/book.vue?macro=true";
import { default as bookingswXl44ph6RmMeta } from "/vercel/path0/src/pages/app/bookings.vue?macro=true";
import { default as indexVDgYl8PleTMeta } from "/vercel/path0/src/pages/app/index.vue?macro=true";
import { default as my_45profileV5HHzewv0QMeta } from "/vercel/path0/src/pages/app/my-profile.vue?macro=true";
import { default as indexr3X8TPnG1tMeta } from "/vercel/path0/src/pages/app/organization-site/[organization_handle]/index.vue?macro=true";
import { default as connect_45organizationsfVhwUOrXZMeta } from "/vercel/path0/src/pages/connect-organization.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as pricing7gFZo9s0BYMeta } from "/vercel/path0/src/pages/pricing.vue?macro=true";
export default [
  {
    name: accept_45invitationjOssVTmHYdMeta?.name ?? "accept-invitation",
    path: accept_45invitationjOssVTmHYdMeta?.path ?? "/accept-invitation",
    meta: accept_45invitationjOssVTmHYdMeta || {},
    alias: accept_45invitationjOssVTmHYdMeta?.alias || [],
    redirect: accept_45invitationjOssVTmHYdMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/accept-invitation.vue").then(m => m.default || m)
  },
  {
    name: index6NX8DPEKEuMeta?.name ?? "admin-areas-area_id",
    path: index6NX8DPEKEuMeta?.path ?? "/admin/areas/:area_id()",
    meta: index6NX8DPEKEuMeta || {},
    alias: index6NX8DPEKEuMeta?.alias || [],
    redirect: index6NX8DPEKEuMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/areas/[area_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91booking_id_936XH1mT6qiQMeta?.name ?? "admin-bookings-booking_id",
    path: _91booking_id_936XH1mT6qiQMeta?.path ?? "/admin/bookings/:booking_id()",
    meta: _91booking_id_936XH1mT6qiQMeta || {},
    alias: _91booking_id_936XH1mT6qiQMeta?.alias || [],
    redirect: _91booking_id_936XH1mT6qiQMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/bookings/[booking_id].vue").then(m => m.default || m)
  },
  {
    name: indexIl5xrqBJZZMeta?.name ?? "admin-bookings",
    path: indexIl5xrqBJZZMeta?.path ?? "/admin/bookings",
    meta: indexIl5xrqBJZZMeta || {},
    alias: indexIl5xrqBJZZMeta?.alias || [],
    redirect: indexIl5xrqBJZZMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/bookings/index.vue").then(m => m.default || m)
  },
  {
    name: new85FWbRSxjVMeta?.name ?? "admin-bookings-new",
    path: new85FWbRSxjVMeta?.path ?? "/admin/bookings/new",
    meta: new85FWbRSxjVMeta || {},
    alias: new85FWbRSxjVMeta?.alias || [],
    redirect: new85FWbRSxjVMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/bookings/new.vue").then(m => m.default || m)
  },
  {
    name: indexwecfAS3OVsMeta?.name ?? "admin-floors-floor_id",
    path: indexwecfAS3OVsMeta?.path ?? "/admin/floors/:floor_id()",
    meta: indexwecfAS3OVsMeta || {},
    alias: indexwecfAS3OVsMeta?.alias || [],
    redirect: indexwecfAS3OVsMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/floors/[floor_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_9324vh3UBTxrMeta?.name ?? "admin-groups-group_id",
    path: _91group_id_9324vh3UBTxrMeta?.path ?? "/admin/groups/:group_id()",
    meta: _91group_id_9324vh3UBTxrMeta || {},
    alias: _91group_id_9324vh3UBTxrMeta?.alias || [],
    redirect: _91group_id_9324vh3UBTxrMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/groups/[group_id].vue").then(m => m.default || m)
  },
  {
    name: indexzdn9NmOJxAMeta?.name ?? "admin-groups",
    path: indexzdn9NmOJxAMeta?.path ?? "/admin/groups",
    meta: indexzdn9NmOJxAMeta || {},
    alias: indexzdn9NmOJxAMeta?.alias || [],
    redirect: indexzdn9NmOJxAMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/groups/index.vue").then(m => m.default || m)
  },
  {
    name: index1kcBTKlDMxMeta?.name ?? "admin",
    path: index1kcBTKlDMxMeta?.path ?? "/admin",
    meta: index1kcBTKlDMxMeta || {},
    alias: index1kcBTKlDMxMeta?.alias || [],
    redirect: index1kcBTKlDMxMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexcjxv4AIu5HMeta?.name ?? "admin-invitations",
    path: indexcjxv4AIu5HMeta?.path ?? "/admin/invitations",
    meta: indexcjxv4AIu5HMeta || {},
    alias: indexcjxv4AIu5HMeta?.alias || [],
    redirect: indexcjxv4AIu5HMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/invitations/index.vue").then(m => m.default || m)
  },
  {
    name: indexMeh049Sek3Meta?.name ?? "admin-members",
    path: indexMeh049Sek3Meta?.path ?? "/admin/members",
    meta: indexMeh049Sek3Meta || {},
    alias: indexMeh049Sek3Meta?.alias || [],
    redirect: indexMeh049Sek3Meta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/members/index.vue").then(m => m.default || m)
  },
  {
    name: indexxxlLLSJ2eoMeta?.name ?? "admin-offerings-offering_id",
    path: indexxxlLLSJ2eoMeta?.path ?? "/admin/offerings/:offering_id()",
    meta: indexxxlLLSJ2eoMeta || {},
    alias: indexxxlLLSJ2eoMeta?.alias || [],
    redirect: indexxxlLLSJ2eoMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/offerings/[offering_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexP0rhIpArTTMeta?.name ?? "admin-offerings",
    path: indexP0rhIpArTTMeta?.path ?? "/admin/offerings",
    meta: indexP0rhIpArTTMeta || {},
    alias: indexP0rhIpArTTMeta?.alias || [],
    redirect: indexP0rhIpArTTMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/offerings/index.vue").then(m => m.default || m)
  },
  {
    name: new6xtljL2VGhMeta?.name ?? "admin-offerings-new",
    path: new6xtljL2VGhMeta?.path ?? "/admin/offerings/new",
    meta: new6xtljL2VGhMeta || {},
    alias: new6xtljL2VGhMeta?.alias || [],
    redirect: new6xtljL2VGhMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/offerings/new.vue").then(m => m.default || m)
  },
  {
    name: index5O8Z0x2y4ZMeta?.name ?? "admin-resources-resource_id",
    path: index5O8Z0x2y4ZMeta?.path ?? "/admin/resources/:resource_id()",
    meta: index5O8Z0x2y4ZMeta || {},
    alias: index5O8Z0x2y4ZMeta?.alias || [],
    redirect: index5O8Z0x2y4ZMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/resources/[resource_id]/index.vue").then(m => m.default || m)
  },
  {
    name: index1ne6oOrwzYMeta?.name ?? "admin-resources",
    path: index1ne6oOrwzYMeta?.path ?? "/admin/resources",
    meta: index1ne6oOrwzYMeta || {},
    alias: index1ne6oOrwzYMeta?.alias || [],
    redirect: index1ne6oOrwzYMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/resources/index.vue").then(m => m.default || m)
  },
  {
    name: generalUqlG69xhGiMeta?.name ?? "admin-settings-general",
    path: generalUqlG69xhGiMeta?.path ?? "/admin/settings/general",
    meta: generalUqlG69xhGiMeta || {},
    alias: generalUqlG69xhGiMeta?.alias || [],
    redirect: generalUqlG69xhGiMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/settings/general.vue").then(m => m.default || m)
  },
  {
    name: editTRg6EBOIxgMeta?.name ?? "admin-site-edit",
    path: editTRg6EBOIxgMeta?.path ?? "/admin/site/edit",
    meta: editTRg6EBOIxgMeta || {},
    alias: editTRg6EBOIxgMeta?.alias || [],
    redirect: editTRg6EBOIxgMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/site/edit.vue").then(m => m.default || m)
  },
  {
    name: indexqe6iXssShFMeta?.name ?? "admin-site",
    path: indexqe6iXssShFMeta?.path ?? "/admin/site",
    meta: indexqe6iXssShFMeta || {},
    alias: indexqe6iXssShFMeta?.alias || [],
    redirect: indexqe6iXssShFMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/site/index.vue").then(m => m.default || m)
  },
  {
    name: indextdUnxETdU8Meta?.name ?? "admin-spaces-space_id",
    path: indextdUnxETdU8Meta?.path ?? "/admin/spaces/:space_id()",
    meta: indextdUnxETdU8Meta || {},
    alias: indextdUnxETdU8Meta?.alias || [],
    redirect: indextdUnxETdU8Meta?.redirect,
    component: () => import("/vercel/path0/src/pages/admin/spaces/[space_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91resource_id_93PDJ9VG6jOxMeta?.name ?? "app-resource_id",
    path: _91resource_id_93PDJ9VG6jOxMeta?.path ?? "/app/:resource_id()",
    meta: _91resource_id_93PDJ9VG6jOxMeta || {},
    alias: _91resource_id_93PDJ9VG6jOxMeta?.alias || [],
    redirect: _91resource_id_93PDJ9VG6jOxMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/[resource_id].vue").then(m => m.default || m)
  },
  {
    name: bookVTiuc2kNqqMeta?.name ?? "app-book",
    path: bookVTiuc2kNqqMeta?.path ?? "/app/book",
    meta: bookVTiuc2kNqqMeta || {},
    alias: bookVTiuc2kNqqMeta?.alias || [],
    redirect: bookVTiuc2kNqqMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/book.vue").then(m => m.default || m)
  },
  {
    name: bookingswXl44ph6RmMeta?.name ?? "app-bookings",
    path: bookingswXl44ph6RmMeta?.path ?? "/app/bookings",
    meta: bookingswXl44ph6RmMeta || {},
    alias: bookingswXl44ph6RmMeta?.alias || [],
    redirect: bookingswXl44ph6RmMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/bookings.vue").then(m => m.default || m)
  },
  {
    name: indexVDgYl8PleTMeta?.name ?? "app",
    path: indexVDgYl8PleTMeta?.path ?? "/app",
    meta: indexVDgYl8PleTMeta || {},
    alias: indexVDgYl8PleTMeta?.alias || [],
    redirect: indexVDgYl8PleTMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/index.vue").then(m => m.default || m)
  },
  {
    name: my_45profileV5HHzewv0QMeta?.name ?? "app-my-profile",
    path: my_45profileV5HHzewv0QMeta?.path ?? "/app/my-profile",
    meta: my_45profileV5HHzewv0QMeta || {},
    alias: my_45profileV5HHzewv0QMeta?.alias || [],
    redirect: my_45profileV5HHzewv0QMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/my-profile.vue").then(m => m.default || m)
  },
  {
    name: indexr3X8TPnG1tMeta?.name ?? "app-organization-site-organization_handle",
    path: indexr3X8TPnG1tMeta?.path ?? "/app/organization-site/:organization_handle()",
    meta: indexr3X8TPnG1tMeta || {},
    alias: indexr3X8TPnG1tMeta?.alias || [],
    redirect: indexr3X8TPnG1tMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/app/organization-site/[organization_handle]/index.vue").then(m => m.default || m)
  },
  {
    name: connect_45organizationsfVhwUOrXZMeta?.name ?? "connect-organization",
    path: connect_45organizationsfVhwUOrXZMeta?.path ?? "/connect-organization",
    meta: connect_45organizationsfVhwUOrXZMeta || {},
    alias: connect_45organizationsfVhwUOrXZMeta?.alias || [],
    redirect: connect_45organizationsfVhwUOrXZMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/connect-organization.vue").then(m => m.default || m)
  },
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pricing7gFZo9s0BYMeta?.name ?? "pricing",
    path: pricing7gFZo9s0BYMeta?.path ?? "/pricing",
    meta: pricing7gFZo9s0BYMeta || {},
    alias: pricing7gFZo9s0BYMeta?.alias || [],
    redirect: pricing7gFZo9s0BYMeta?.redirect,
    component: () => import("/vercel/path0/src/pages/pricing.vue").then(m => m.default || m)
  }
]