export const apiFetch = $fetch.create({
  baseURL: 'https://api.devifex.com',
});

/**
 * Composable function to get the API access token.
 * @returns an access token (if user is authenticated) or `undefined`.
 */
export function useApiAccessToken() {
  return useState<string | undefined>('api_access_token');
}

/**
 * Function to get the ofetch instance for the API.
 * @param accessToken API access token.
 * @param organizationID Organization ID.
 * @returns ofetch instance.
 */
export function getApiFetchInstance(accessToken: MaybeRefOrGetter<string | null | undefined>, organizationID?: MaybeRefOrGetter<string | null | undefined>) {
  const runtimeConfig = useRuntimeConfig();

  const organizationIDValue = toValue(organizationID);
  return $fetch.create({
    baseURL: runtimeConfig.public.apiBaseUrl,
    headers: {
      ...organizationIDValue ? { 'DF-Organization-ID': organizationIDValue } : {},
      Authorization: `Bearer ${toValue(accessToken)}`,
    },
  });
}
