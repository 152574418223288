import type { ApiPaths, JsonResponse } from '../../api';
import { getApiFetchInstance, useApiAccessToken } from '../../api';

export type OrganizationReadByMember = JsonResponse<ApiPaths['/core/v1']['/organizations/my']['get'], 200>[number];

/**
 * Composable function to get the organizations that the user is a member of or owns.
 * @returns The organizations that the user is a member of or owns.
 */
export async function useCurrentUserOrganizations() {
  const myOrganizations = useState<OrganizationReadByMember[]>('current_user_organizations', () => []);

  // If we've already tried to fetch the organization, return it.
  // const triedToFetch = useState<boolean>('current_user_organizations_tried_to_fetch', () => false);
  // if (triedToFetch.value) {
  //   return myOrganizations;
  // }

  // triedToFetch.value = true;
  const accessToken = useApiAccessToken();
  if (!accessToken.value) {
    return myOrganizations;
  }
  const authenticatedFetch = getApiFetchInstance(accessToken);
  myOrganizations.value = await authenticatedFetch<JsonResponse<ApiPaths['/core/v1']['/organizations/my']['get'], 200>>('/core/v1/organizations/my');

  return myOrganizations;
}

/**
 * Composable function to get the current organization ID from the cookie.
 * @returns The current organization ID.
 */
export async function useCurrentOrganizationId() {
  const currentOrgId = useCookie<string | undefined>('current_organization_id');

  if (!currentOrgId.value) {
    const myOrganizations = await useCurrentUserOrganizations();
    if (myOrganizations.value.length) {
      currentOrgId.value = myOrganizations.value[0].id;
    }
  }
  return currentOrgId;
}

/**
 * Composable function to get the current organization.
 * @returns The current organization.
 */
export async function useCurrentOrganization() {
  const currentOrgId = await useCurrentOrganizationId();
  const organization = useState<OrganizationReadByMember | undefined>('current_organization');

  // If we've already tried to fetch the organization, return it.
  const triedToFetch = useState<boolean>('current_organization_tried_to_fetch', () => false);
  if (triedToFetch.value) {
    return organization;
  }

  if (!organization.value || organization.value.id !== currentOrgId.value) {
    const myOrganizations = await useCurrentUserOrganizations();
    if (myOrganizations.value.length) {
      organization.value = myOrganizations.value.find(org => org.id === currentOrgId.value) || myOrganizations.value[0];
      currentOrgId.value = organization.value.id;
    }
    triedToFetch.value = true;
  }
  return organization;
}
