<script setup lang="ts">
import { useApiAccessToken } from '~/src/shared/api';
import { useCurrentOrganizationId } from './src/shared/lib/current-user';

const client = useLogtoClient();
const user = useLogtoUser();

const currentOrgID = await useCurrentOrganizationId();
const apiAccessToken = useApiAccessToken();

if (user) {
  await callOnce(async () => {
    if (!client) {
      throw new Error('Logto client is not available. Failed to fetch access_token.');
    }

    const isAuthenticated = await client.isAuthenticated();
    if (!isAuthenticated) {
      return;
    }

    // Get organizations list of current user
    const organizationData: { id: string; name: string; description: string | null }[] = user.organization_data;
    const organizationIDs = organizationData.map(org => org.id);

    // if currentOrgID is not in the list of organizations, reset it
    if (!organizationIDs.includes(currentOrgID.value!)) {
      currentOrgID.value = undefined;
    }

    // if currentOrgID is not set, set it to the first organization
    if (!currentOrgID.value && organizationData.length) {
      currentOrgID.value = organizationData[0].id;
    }

    // fetch ID token
    apiAccessToken.value = await client.getAccessToken('https://api.devifex.com');
  });
}
</script>

<template>
  <NuxtLoadingIndicator color="#6366f1" :height="6" />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
