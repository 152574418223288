import * as Sentry from '@sentry/vue';

async function lazyLoadSentryIntegrations() {
  // don't load on server
  if (!process.client) {
    return;
  }

  const { replayIntegration } = await import('@sentry/vue');
  Sentry.addIntegration(replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }));
}

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const config = useRuntimeConfig();

    if (!import.meta.env.PROD || !config.public.SENTRY_DSN) {
      return;
    }

    Sentry.init({
      app: vueApp,
      dsn: config.public.SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({
          router: useRouter(),
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: Number.parseFloat(config.public.SENTRY_TRACES_SAMPLE_RATE),

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

      // This sets the sample rate. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: Number.parseFloat(config.public.SENTRY_REPLAY_SAMPLE_RATE),

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: Number.parseFloat(config.public.SENTRY_ERROR_REPLAY_SAMPLE_RATE),
    });

    // Lazy-load the replay integration to reduce bundle size
    lazyLoadSentryIntegrations();
  },
});
